import React from "react"

import Layout from "../components/layout"
import ContactUs from "../components/screens/ContactUs"
import { Helmet } from "react-helmet"

const Contact = () => (
  <Layout pageInfo={{ pageName: "Contact Us" }} sitePage="site-page">
    <Helmet defer={false}>
        <title>Contact Us</title>
        <meta name="description" content="OpenTechiz 's contactus page" />
    </Helmet>
    <ContactUs />
  </Layout>
)

export default Contact
