import React, { Component } from "react"
import {
  Button,
  Col,
  Form,
  Image,
} from "react-bootstrap"
import ReCAPTCHA from 'react-google-recaptcha'
import ReactDOM from 'react-dom'
import LazyLoad from 'react-lazy-load'

class ContactUs extends Component {
  constructor() {
    super();
    this.state = {
      data: null,
    };
    this._reCaptchaRef = React.createRef();
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleCaptchaResponseChange = this.handleCaptchaResponseChange.bind(this);
  }

  handleCaptchaResponseChange(response) {
    this.setState({
      recaptchaResponse: response,
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const data = new FormData(form);
    const json = Object.assign(...Array.from(data, ([x,y]) => ({[x]:y})));

    fetch('https://cms.opentechiz.com/mailer', {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': 'application/json',
      },
      body: JSON.stringify(json),
    })
    .then(res => res.json())
    .then(data => {
      let messageClass = "message";
      if (data.success){
        messageClass += " success-msg"
        // do something
      } else {
        messageClass += " failure-msg"
        // else something
      }
      this.setState({
        data: data.message,
        messageClass: messageClass
      });
    })
    .catch(function(error){ console.log(error) });

    this._reCaptchaRef.current.reset();
    ReactDOM.findDOMNode(this.messageForm).reset();
  }

  render() {
    return (
      <div className="wrapper-contact">
        <iframe data-src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3724.984638361172!2d105.78469205107443!3d20.99325288594907!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x313454ccd1f30959%3A0x870df4eac4af46d7!2sLinen%20House!5e0!3m2!1svi!2s!4v1568356127777!5m2!1svi!2s"
          width="100%" className="map-contact lazyload" allowfullscreen=""/>
        <div className="middle-contact">
          <div className="form-contact">
            <h1 className="title-contact">CONTACT US</h1>
            <div className="content-contact">
              <p className="p-content-contact">
                Are you interested in working with us?
              </p>
              <p className="p-content-contact">
                Please leave your information and we will reach out to{" "}
              </p>
            </div>
            <Col sm={12} lg={12} md={12} className="box-form-contact">
              <Form onSubmit={this.handleSubmit} ref={ form => this.messageForm = form }>
                  <Form.Group controlId="formBasicFullName">
                    <Form.Control required type="text" name="full-name" placeholder="Full name *" />
                  </Form.Group>
                  <Form.Group controlId="formBasicEmail">
                    <Form.Control required type="email" name="email" placeholder="Email *" />
                  </Form.Group>
                  <Form.Group controlId="formBasicCompany">
                    <Form.Control required type="text" name="company" placeholder="Company *" />
                  </Form.Group>
                  <Form.Group controlId="formBasicPhone">
                    <Form.Control required type="text" name="phone" placeholder="Phone *" />
                  </Form.Group>
                  <Form.Group controlId="formBasicTitle">
                    <Form.Control required type="text" name="title" placeholder="Title *" />
                  </Form.Group>
                  <Form.Group controlId="formBasicAboutUs">
                    <Form.Control required type="text" name="about-us" placeholder="How did you know about us *" />
                  </Form.Group>
                  <Form.Group controlId="formBasicHelp">
                    <Form.Control required as="textarea" name="help" rows="4" placeholder="How do you want us to help *" />
                  </Form.Group>
                  <LazyLoad className="recaptcha">
                    <ReCAPTCHA
                      ref={this._reCaptchaRef}
                      sitekey="6LeJlL4UAAAAACizPm290BBxxlrK_63lsnPlCnxh"
                      onChange={(val) => { this.setState({recapture:val}); }}
                      className="recaptcha-home"
                      loading="lazyload"
                    />
                  </LazyLoad>
                  <p className={this.state.messageClass}>
                    {this.state.data}
                  </p>
                <div className="btn-contact">
                  <Button type="submit" className="btn-submit">
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>
          </div>
          <div className="contact-info">
            <div className="box-contact-info">
              <p className="title-contact-info">
                Contact info
              </p>
              <div className="description-contact">
                <p className="text-description-contact">Address: 4th Floor, A1 Building, Ecolife Capitol, 58 To Huu, Trung Van, Nam Tu Liem district, Ha Noi</p>
                <p className="text-description-contact">Website: www.opentechiz.com</p>
                <p className="text-description-contact">Facebook: <a href="https://www.facebook.com/Open-Techiz-Business-Technology-247782725932121/">Open-Techiz</a></p>
                <p className="text-description-contact">Email: <a href="mailto:support@opentechiz.com">support@opentechiz.com</a></p>
              </div>
              <div className="icon-network">
                <a href="https://www.facebook.com/Open-Techiz-Business-Technology-247782725932121/">
                  <Image
                    className="logo-social lazyload"
                    alt="900x500"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                    data-src={require ("../../../images/png/facebook-logo-button.png")}
                  />
                </a>
                <a href="https://www.facebook.com/Open-Techiz-Business-Technology-247782725932121/">
                  <Image
                    className="logo-social lazyload"
                    alt="900x500"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                    data-src={require ("../../../images/png/twitter-logo-button.png")}
                  />
                </a>
                <a href="https://www.facebook.com/Open-Techiz-Business-Technology-247782725932121/">
                  <Image
                    className="logo-social lazyload"
                    alt="900x500"
                    src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mP8Xw8AAoMBgDTD2qgAAAAASUVORK5CYII="
                    data-src={require ("../../../images/png/google-plus-logo-button.png")}
                  />
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default ContactUs;
